import React, {
  forwardRef,
  RefForwardingComponent,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { RouteComponentProps, useHistory } from "react-router";

import { CheckoutPayment } from "@components/organisms";
import { useCart, useCheckout, useUserDetails } from "@saleor/sdk";
import { ShopContext } from "@temp/components/ShopProvider/context";
import { CHECKOUT_STEPS } from "@temp/core/config";
import { IAddress, ICardData, IFormError, ISelectedPaymentTransactionType } from "@types";
import { filterNotEmptyArrayItems } from "@utils/misc";
import { useAlert } from "react-alert";
import { catchInActiveCheckoutError } from "src/helpers";
import { PAYMENT_TYPE_CODES } from "src/constants"
import { useFlags } from "launchdarkly-react-client-sdk";

export interface ICheckoutPaymentSubpageHandles {
  submitPayment: () => void;
}

interface IProps extends RouteComponentProps<any> {
  selectedPaymentGateway?: string;
  selectedPaymentGatewayToken?: string;
  selectPaymentGateway: (paymentGateway: string) => void;
  changeSubmitProgress: (submitInProgress: boolean) => void;
  paymentTransactionType?: ISelectedPaymentTransactionType | null;
  paymentTransactionTypeOptions?: ISelectedPaymentTransactionType[];
  selectedPaymentTransactionType: (paymentTransactionType?: ISelectedPaymentTransactionType | null | undefined) => void;
  checkoutPaymentUpdate: any;
  linkJRPayUserAccount: any;
}

const CheckoutPaymentSubpageWithRef: RefForwardingComponent<
  ICheckoutPaymentSubpageHandles,
  IProps
> = (
  {
    selectedPaymentGateway,
    selectedPaymentGatewayToken,
    changeSubmitProgress,
    selectPaymentGateway,
    paymentTransactionType,
    selectedPaymentTransactionType,
    paymentTransactionTypeOptions,
    checkoutPaymentUpdate,
    linkJRPayUserAccount,
    ...props
  }: IProps,
  ref
) => {
  const history = useHistory();
  const { data: user } = useUserDetails();
  const {
    checkout,
    billingAsShipping,
    setBillingAddress,
    setBillingAsShippingAddress,
    selectedBillingAddressId,
    availablePaymentGateways,
    promoCodeDiscount,
    addPromoCode,
    removePromoCode,
    // createPayment,
  } = useCheckout();
  const { items } = useCart();
  const { countries } = useContext(ShopContext);
  const { jrMallShowJrPayPaymentMethod } = useFlags()

  // same value with "billingAsShipping" but using this variable we're defaulting the value to true if by default or undefined 
  // const defaultBillingAsShipping = typeof billingAsShipping === "undefined" || billingAsShipping === null ? true : billingAsShipping;
  const defaultBillingAsShipping = true; // As per mgt, we should always set the billing address same to shipping address

  const isShippingRequiredForProducts =
    items &&
    items.some(
      ({ variant }) => variant.product?.productType.isShippingRequired
    );

  const [billingErrors, setBillingErrors] = useState<IFormError[]>([]);
  const [gatewayErrors, setGatewayErrors] = useState<IFormError[]>([]);
  const [promoCodeErrors, setPromoCodeErrors] = useState<IFormError[]>([]);
  const [billingAsShippingState, setBillingAsShippingState] = useState(
    defaultBillingAsShipping
  );

  const alert = useAlert();
  const showErrorAlert = (title: string, message: string, field: string | null) => {
    if (field) {
      setBillingErrors([{ field, message }]);
    }
    
    alert.error({
      content: message,
      title,
    }, {
      timeout: 2000, // custom timeout just for this one alert
      // onOpen: () => {}, // callback that will be executed after this alert open
      // onClose: () => {} // callback that will be executed after this alert is removed
    })
  };

  useEffect(() => {
    setBillingAsShippingState(defaultBillingAsShipping);
  }, [billingAsShipping]);

  const checkoutBillingAddress = checkout?.billingAddress
    ? {
        ...checkout?.billingAddress,
        phone: checkout?.billingAddress?.phone || undefined,
      }
    : undefined;
  const paymentGateways = availablePaymentGateways
    ? availablePaymentGateways
    : [];

  const checkoutBillingFormId = "billing-form";
  const checkoutBillingFormRef = useRef<HTMLFormElement>(null);
  const checkoutGatewayFormId = "gateway-form";
  const checkoutGatewayFormRef = useRef<HTMLFormElement>(null);
  const checkoutNewAddressFormId = "new-address-form";
  const promoCodeDiscountFormId = "discount-form";
  const promoCodeDiscountFormRef = useRef<HTMLFormElement>(null);

  useImperativeHandle(ref, () => ({
    submitPayment: () => {
      if (billingAsShippingState) {
        handleSetBillingAddress();
      } else if (user && selectedBillingAddressId) {
        checkoutBillingFormRef.current?.dispatchEvent(
          new Event("submit", { cancelable: true })
        );
      } else {
        // TODO validate form
        checkoutBillingFormRef.current?.dispatchEvent(
          new Event("submit", { cancelable: true })
        );
      }
    },
  }));

  const handleProcessPayment = async (
    gateway: string,
    token: string,
    cardData?: ICardData
  ) => {
    // const { dataError } = await createPayment(gateway, token, cardData);
    // const errors = dataError?.error;
    // changeSubmitProgress(false);
    // if (errors) {
    //   setGatewayErrors(errors);
    // } else {
    //   setGatewayErrors([]);
    //   history.push(CHECKOUT_STEPS[2].nextStepLink);
    // }

    changeSubmitProgress(false);
    history.push(CHECKOUT_STEPS[2].nextStepLink);
  };
  const handlePaymentGatewayError = () => {
    changeSubmitProgress(false);
  };
  const handleSetBillingAddress = async (
    address?: IAddress,
    email?: string,
    userAddressId?: string
  ) => {
    const REQUIRED = "Required!";
    if (!address && !billingAsShippingState) {
      showErrorAlert(REQUIRED, "Please provide billing address.", "");
      return;
    }

    const billingEmail = user?.email || email;
    if (
      !billingEmail &&
      !billingAsShippingState &&
      !isShippingRequiredForProducts
    ) {
      showErrorAlert(REQUIRED, "Please provide email address.", "email");
      return;
    }

    if (
        !billingAsShippingState 
        && (!address?.placeId && (!address?.longitude || !address?.latitude))
      ) {
      showErrorAlert(REQUIRED, "Please select an address. e.g. Landmark, Bldg, Street", "streetAddress1");
      return;
    }

    if (
        !billingAsShippingState &&
        (!address?.phone || address.phone === "")
      ) {
      showErrorAlert(REQUIRED, "Please enter phone number", "phone");
      return;
    }

    if (!paymentTransactionType) {
      showErrorAlert(REQUIRED, "Please select a method of payment", "payment_method");
      return;
    }

    let errors;
    changeSubmitProgress(true);
    if (billingAsShippingState && isShippingRequiredForProducts) {
      const { dataError } = await setBillingAsShippingAddress();
      errors = dataError?.error;
    } else {
      const { dataError } = await setBillingAddress(
        {
          ...address,
          id: userAddressId,
        },
        billingEmail
      );
      errors = dataError?.error;
    }
    if (errors) {
      changeSubmitProgress(false);
      setBillingErrors(errors);
      showErrorAlert(REQUIRED, (errors.length && errors.length > 0 && errors[0].message) || "An error occurs upon saving billing details.", null);

      // Check if current checkout is IN-ACTIVE
      catchInActiveCheckoutError(errors);
    } else {
      setBillingErrors([]);
      // if (promoCodeDiscountFormRef.current) {
      //   promoCodeDiscountFormRef.current?.dispatchEvent(
      //     new Event("submit", { cancelable: true })
      //   );
      // } else if (checkoutGatewayFormRef.current) {
      //   checkoutGatewayFormRef.current.dispatchEvent(
      //     new Event("submit", { cancelable: true })
      //   );
      // } else {
      //   changeSubmitProgress(false);
      //   setGatewayErrors([{ message: "Please choose payment method." }]);
      // }

      // Update payment method 
      const checkoutPayment = await checkoutPaymentUpdate({ 
        variables: {
          checkoutId: checkout?.id,
          paymentTransactionTypeId: paymentTransactionType?.id
        } 
      });

      // Check if:
      // 1. Payment method is JRPay
      // 2. If JRPay, check if user acount already linked into jrpay
      // 3. If still unlink proceed to linking process using the return redirectUrl
      if (paymentTransactionType?.code === PAYMENT_TYPE_CODES.JR_PAY) {
        const { data } = await linkJRPayUserAccount({
          variables: {
            userId: user?.id,
          } 
        });
        const redirectUrl = data?.accountUserGenerateJrpayLinkId?.jrpayLinks?.redirectUrl;
        if (redirectUrl) {
          window.location.href = `${redirectUrl}?redirect_url=${window.location.origin}/checkout/review`;
          return;
        }
      }

      if (checkoutPayment?.data?.checkoutUpdatePaymentTransactionType?.checkout?.paymentTransactionType) {
        history.push(CHECKOUT_STEPS[2].nextStepLink);
      } else {
        showErrorAlert("Error!", "Error occurs upon saving payment type.", null);
      }
      changeSubmitProgress(false);
    }
  };
  const handleAddPromoCode = async (promoCode: string) => {
    const { dataError } = await addPromoCode(promoCode);
    const errors = dataError?.error;
    if (errors) {
      changeSubmitProgress(false);
      setPromoCodeErrors(errors);
    } else {
      setPromoCodeErrors([]);
      if (checkoutGatewayFormRef.current) {
        checkoutGatewayFormRef.current.dispatchEvent(
          new Event("submit", { cancelable: true })
        );
      } else {
        changeSubmitProgress(false);
        setGatewayErrors([{ message: "Please choose payment method." }]);
      }
    }
  };
  const handleRemovePromoCode = async (promoCode: string) => {
    const { dataError } = await removePromoCode(promoCode);
    const errors = dataError?.error;
    if (errors) {
      changeSubmitProgress(false);
      setPromoCodeErrors(errors);
    } else {
      setPromoCodeErrors([]);
      if (checkoutGatewayFormRef.current) {
        checkoutGatewayFormRef.current.dispatchEvent(
          new Event("submit", { cancelable: true })
        );
      } else {
        changeSubmitProgress(false);
        setGatewayErrors([{ message: "Please choose payment method." }]);
      }
    }
  };
  const handleSubmitUnchangedDiscount = () => {
    if (checkoutGatewayFormRef.current) {
      checkoutGatewayFormRef.current.dispatchEvent(
        new Event("submit", { cancelable: true })
      );
    } else {
      changeSubmitProgress(false);
      setGatewayErrors([{ message: "Please choose payment method." }]);
    }
  };

  const defaultBillingAddress = user?.addresses ? user?.addresses.find(address => address?.isDefaultBillingAddress) : null;

  return (
    <CheckoutPayment
      {...props}
      user={user}
      billingErrors={billingErrors}
      gatewayErrors={gatewayErrors}
      billingFormId={checkoutBillingFormId}
      billingFormRef={checkoutBillingFormRef}
      userAddresses={user?.addresses
        ?.filter(filterNotEmptyArrayItems)
        .map(
          ({
            isDefaultBillingAddress,
            isDefaultShippingAddress,
            phone,
            ...address
          }) => ({
            ...address,
            isDefaultBillingAddress: !!isDefaultBillingAddress,
            isDefaultShippingAddress: !!isDefaultShippingAddress,
            phone: phone ? phone : undefined,
          })
        )}
      selectedUserAddressId={selectedBillingAddressId || defaultBillingAddress?.id}
      checkoutBillingAddress={checkoutBillingAddress}
      countries={countries}
      paymentGateways={paymentGateways}
      selectedPaymentGateway={selectedPaymentGateway}
      selectedPaymentGatewayToken={selectedPaymentGatewayToken}
      selectPaymentGateway={selectPaymentGateway}
      setBillingAddress={handleSetBillingAddress}
      billingAsShippingPossible={!!isShippingRequiredForProducts}
      billingAsShippingAddress={billingAsShippingState}
      setBillingAsShippingAddress={setBillingAsShippingState}
      promoCodeDiscountFormId={promoCodeDiscountFormId}
      promoCodeDiscountFormRef={promoCodeDiscountFormRef}
      promoCodeDiscount={{
        voucherCode: promoCodeDiscount?.voucherCode,
      }}
      addPromoCode={handleAddPromoCode}
      removeVoucherCode={handleRemovePromoCode}
      submitUnchangedDiscount={handleSubmitUnchangedDiscount}
      promoCodeErrors={promoCodeErrors}
      gatewayFormId={checkoutGatewayFormId}
      gatewayFormRef={checkoutGatewayFormRef}
      userId={user?.id}
      newAddressFormId={checkoutNewAddressFormId}
      processPayment={handleProcessPayment}
      onGatewayError={handlePaymentGatewayError}
      paymentTransactionType={paymentTransactionType || null}
      selectedPaymentTransactionType={selectedPaymentTransactionType}
      paymentTransactionTypeOptions={paymentTransactionTypeOptions}
      jrMallShowJrPayPaymentMethod={jrMallShowJrPayPaymentMethod}
    />
  );
};

const CheckoutPaymentSubpage = forwardRef(CheckoutPaymentSubpageWithRef);

export { CheckoutPaymentSubpage };
