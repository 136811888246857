import React from "react";
import "../globalStyles/scss/index.scss";
import Layout from '../layout';
import Routes from '../routes';
import 'swiper/swiper.scss'
import 'swiper/components/pagination/pagination.scss';
import { withLDProvider } from "launchdarkly-react-client-sdk";
import { ctxProvider } from "./LaunchDarklyContext";

const App = () => {
  return (
    <Layout>
      <Routes />
    </Layout>
  )
};

export default withLDProvider({
  clientSideID: process.env.REACT_APP_LD_ID,
  user: {
    ...ctxProvider()
  },
})(App);
