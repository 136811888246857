export const ctxProvider = () => {
    const storage = localStorage.getItem('ldProvider')
 
    if(storage) {
     const { email, id, lastName, firstName } = JSON.parse(storage);
 
     return {
       email,
       key: id,
       name: `${firstName} ${lastName}`,
     }
    }
 
}